import axios from 'axios'

export default class BancoCentralService {
  async getTaxasJuros() {
    const url =
      'https://olinda.bcb.gov.br/olinda/servico/taxaJuros/versao/v2/odata/TaxasJurosDiariaPorInicioPeriodo?$top=50&$filter=(Modalidade%20eq%20%27Cr%C3%A9dito%20pessoal%20consignado%20p%C3%BAblico%20-%20Pr%C3%A9-fixado%27)&$format=json'
    const { data } = await axios.get(url)
    return data
  }
}
